@media(max-width:990px){
    .css-syszer{
        width: 340px !important;
    }
    .css-1ym4kh4{
        padding: 0 !important;    
    }
    .css-18zmk4u{
        padding: 0 !important;  
    }
    .table-cls{
        padding: 5px;
    }
    .prfile{
        padding: 10px;
    }
    /* .prfile-main{
        padding-left: 0 !important;
    } */
    .tabpnl{
        padding: 10px;
    }
    .tabl-cll{
        padding: 0 !important;
    width: 20px;
    }
    .tble-data{
        padding: 5px !important;
        width: 20px;
        text-align: center;
        font-size: 13px;
    }
    .tble-dt1{
        width: 17%;
        padding: 5px !important;
        text-align: center;
        font-size: 13px;
    }
    .table-hd{
        width: 15%;
        font-size: 14px;
    }
    .tble-av{
        width: 10% !important;
    }
    .retail-text{
        width: 100%;
    }
 
 
}
.lg-img img{
    width: 135px;
}
.log-in{
    align-items: center;
}
@media(max-width:666px){
    .css-1tnm92u{
        padding: 24px 3px !important;
    }
    .lg-img img{
        width: 125px;
    }
    .log-in{
        align-items: baseline;
    }
}
/* .css-18ebnrr-MuiTableCell-root{
    padding: 0 !important;
} */
.css-1texec3{
    padding: 7px 0 !important;
}
.css-15r37fr{
    padding: 0 !important;  
}
.css-1q6nc1y-MuiTableCell-root{
    padding: 0 !important;     
}
.whiteicon{
    color: #fff !important;
}
.css-1d6wzja-MuiButton-startIcon{
    margin-right: 0;
}
.css-197i0ye-MuiBadge-badge{
    z-index: 9999;
}
.css-6xugel{
    margin-right: 0 !important;
}
.css-1h9ar7v{
    z-index: 9999;
}
@media(max-width:400px){
    .css-1tnm92u{
        padding: 24px 3px !important;
    }
    .lg-img img{
        width: 90px;
    }
}
